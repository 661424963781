import React from 'react';
import './Bio.css';

function Bio() {
  return (
    <div className="bio-container">
      <h1>Bio</h1>
      <img src="/profile.jpg" alt="Profile" className="profile-image" />

      <p>01.2000 - Born</p>
      <p>09.2014 - Started high school and staying at dormitory</p>
      <p>08.2018 - Went to Belgium on AFS</p>
      <p>09.2019 - Started Bogazici University</p>
      <p>10.2021 - Started working for Transkriptor</p>
      <p>05.2024 - Left Transkriptor</p>
      
    </div>
  );
}

export default Bio;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Essay() {
  const { id } = useParams();
  const [essay, setEssay] = useState(null);

  useEffect(() => {
    fetch('/essays.json')
      .then(response => response.json())
      .then(data => {
        const foundEssay = data.find(e => e.id === parseInt(id));
        setEssay(foundEssay);
      });
  }, [id]);

  if (!essay) {
    return <div>Loading...</div>;
  }

  return (
    <div className="essay-content">
      <br></br>
      <h1>{essay.title}</h1>
      <p>{essay.month} {essay.year}</p>
      <div>{essay.content}</div>
    </div>
  );
}

export default Essay;

import React, { useState, useEffect } from 'react';

function FAQ() {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    fetch('/faq.json')
      .then(response => response.json())
      .then(data => setFaq(data));
  }, []);

  return (
    <div>
      <br></br>
      <h1>FAQ</h1>
      <ul>
        {faq.map((item, index) => (
          <li key={index}>
            <strong>{item.question}</strong>
            <p>{item.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FAQ;

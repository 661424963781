import React, { useState, useEffect } from 'react';

function RAQ() {
  const [raq, setRaq] = useState([]);

  useEffect(() => {
    fetch('/raq.json')
      .then(response => response.json())
      .then(data => setRaq(data));
  }, []);

  return (
    <div>
      <br></br>
      <h1>RAQ</h1>
      <ul>
        {raq.map((item, index) => (
          <li key={index}>
            <strong>{item.question}</strong>
            <p>{item.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RAQ;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Essays() {
  const [essays, setEssays] = useState([]);

  useEffect(() => {
    fetch('/essays.json')
      .then(response => response.json())
      .then(data => setEssays(data));
  }, []);

  return (
    <div className="essay-list">
      <br></br>
      <h1>Essays</h1>
      <ul>
        {essays.map(essay => (
          <li key={essay.id}>
            <Link to={`/essays/${essay.id}`}>{essay.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Essays;

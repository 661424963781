import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Bio from './components/Bio';
import FAQ from './components/FAQ';
import RAQ from './components/RAQ';
import Essays from './components/Essays';
import Essay from './components/Essay';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰
        </div>
        <nav className={menuOpen ? 'open' : ''}>
          <div className="close-btn" onClick={closeMenu}>
            ×
          </div>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/bio" onClick={closeMenu}>Bio</Link></li>
            <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
            <li><Link to="/raq" onClick={closeMenu}>RAQ</Link></li>
            <li><Link to="/essays" onClick={closeMenu}>Essays</Link></li>
            <li><a href="mailto:mevluthancerkiran@gmail.com" onClick={closeMenu}>Contact</a></li>
          </ul>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bio" element={<Bio />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/raq" element={<RAQ />} />
            <Route path="/essays" element={<Essays />} />
            <Route path="/essays/:id" element={<Essay />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>:)</h1>
      <img src="/home.jpg" alt="Home" className="home-image" />
      <footer>
        &copy; 2024 Mevlut Hancerkiran
      </footer>
    </div>
  );
}

export default Home;
